export const COUNTRIES_MAP = {
  AFG: 'Afghanistan',
  ALB: 'Albania',
  DZA: 'Algeria',
  AND: 'Andorra',
  AGO: 'Angola',
  ATG: 'Antigua and Barbuda',
  ARG: 'Argentina',
  ARM: 'Armenia',
  AUS: 'Australia',
  AUT: 'Austria',
  AZE: 'Azerbaijan',
  BHR: 'Bahrain',
  BGD: 'Bangladesh',
  BRB: 'Barbados',
  BLR: 'Belarus',
  BEL: 'Belgium',
  BLZ: 'Belize',
  BEN: 'Benin',
  BTN: 'Bhutan',
  BOL: 'Bolivia (Plurinational State of)',
  BIH: 'Bosnia and Herzegovina',
  BWA: 'Botswana',
  BRA: 'Brazil',
  BRN: 'Brunei Darussalam',
  BGR: 'Bulgaria',
  BFA: 'Burkina Faso',
  BDI: 'Burundi',
  KHM: 'Cambodia',
  CMR: 'Cameroon',
  CAN: 'Canada',
  CPV: 'Cabo Verde',
  CAF: 'Central African Republic',
  TCD: 'Chad',
  CHL: 'Chile',
  CHN: 'China',
  COL: 'Colombia',
  COM: 'Comoros',
  COG: 'Congo',
  CRI: 'Costa Rica',
  CIV: 'Côte d\'Ivoire',
  HRV: 'Croatia',
  CUB: 'Cuba',
  CYP: 'Cyprus',
  CZE: 'Czechia',
  PRK: 'Democratic People\'s Republic of Korea',
  COD: 'Democratic Republic of the Congo',
  DNK: 'Denmark',
  DJI: 'Djibouti',
  DMA: 'Dominica',
  DOM: 'Dominican Republic',
  ECU: 'Ecuador',
  EGY: 'Egypt',
  SLV: 'El Salvador',
  GNQ: 'Equatorial Guinea',
  ERI: 'Eritrea',
  EST: 'Estonia',
  ETH: 'Ethiopia',
  FJI: 'Fiji',
  FIN: 'Finland',
  FRA: 'France',
  GAB: 'Gabon',
  GMB: 'Gambia',
  GEO: 'Georgia',
  DEU: 'Germany',
  GHA: 'Ghana',
  GRC: 'Greece',
  GRD: 'Grenada',
  GTM: 'Guatemala',
  GIN: 'Guinea',
  GNB: 'Guinea-Bissau',
  GUY: 'Guyana',
  HTI: 'Haiti',
  HND: 'Honduras',
  HUN: 'Hungary',
  ISL: 'Iceland',
  IND: 'India',
  IDN: 'Indonesia',
  IRN: 'Iran (Islamic Republic of)',
  IRQ: 'Iraq',
  IRL: 'Ireland',
  ISR: 'Israel',
  ITA: 'Italy',
  JAM: 'Jamaica',
  JPN: 'Japan',
  JOR: 'Jordan',
  KAZ: 'Kazakhstan',
  KEN: 'Kenya',
  KWT: 'Kuwait',
  KGZ: 'Kyrgyzstan',
  LAO: 'Lao People\'s Democratic Republic',
  LVA: 'Latvia',
  LBN: 'Lebanon',
  LSO: 'Lesotho',
  LBR: 'Liberia',
  LBY: 'Libya',
  LIE: 'Liechtenstein',
  LTU: 'Lithuania',
  LUX: 'Luxembourg',
  MDG: 'Madagascar',
  MWI: 'Malawi',
  MYS: 'Malaysia',
  MDV: 'Maldives',
  MLI: 'Mali',
  MLT: 'Malta',
  MRT: 'Mauritania',
  MUS: 'Mauritius',
  MEX: 'Mexico',
  MCO: 'Monaco',
  MNG: 'Mongolia',
  MNE: 'Montenegro',
  MAR: 'Morocco',
  MOZ: 'Mozambique',
  MMR: 'Myanmar',
  NAM: 'Namibia',
  NPL: 'Nepal',
  NLD: 'Netherlands',
  NZL: 'New Zealand',
  NIC: 'Nicaragua',
  NER: 'Niger',
  NGA: 'Nigeria',
  NOR: 'Norway',
  PSE: 'Occupied Palestinian Territory',
  OMN: 'Oman',
  PAK: 'Pakistan',
  PAN: 'Panama',
  PNG: 'Papua New Guinea',
  PRY: 'Paraguay',
  PER: 'Peru',
  PHL: 'Philippines',
  POL: 'Poland',
  PRT: 'Portugal',
  PRI: 'Puerto Rico (United States of America)',
  QAT: 'Qatar',
  KOR: 'Republic of Korea',
  MDA: 'Republic of Moldova',
  ROU: 'Romania',
  RUS: 'Russian Federation',
  RWA: 'Rwanda',
  KNA: 'Saint Kitts and Nevis',
  LCA: 'Saint Lucia',
  VCT: 'Saint Vincent and the Grenadines',
  SMR: 'San Marino',
  STP: 'Sao Tome and Principe',
  SAU: 'Saudi Arabia',
  SEN: 'Senegal',
  SRB: 'Serbia',
  SYC: 'Seychelles',
  SLE: 'Sierra Leone',
  SGP: 'Singapore',
  SVK: 'Slovakia',
  SVN: 'Slovenia',
  SOM: 'Somalia',
  ZAF: 'South Africa',
  ESP: 'Spain',
  LKA: 'Sri Lanka',
  SDN: 'Sudan (pre-2011)',
  SUR: 'Suriname',
  SWZ: 'Swaziland',
  SWE: 'Sweden',
  CHE: 'Switzerland',
  SYR: 'Syrian Arab Republic',
  TJK: 'Tajikistan',
  THA: 'Thailand',
  MKD: 'The former Yugoslav Republic of Macedonia',
  TLS: 'Timor-Leste',
  TGO: 'Togo',
  TTO: 'Trinidad and Tobago',
  TUN: 'Tunisia',
  TUR: 'Turkey',
  TKM: 'Turkmenistan',
  UGA: 'Uganda',
  UKR: 'Ukraine',
  ARE: 'United Arab Emirates',
  GBR: 'United Kingdom',
  TZA: 'United Republic of Tanzania',
  USA: 'United States of America',
  URY: 'Uruguay',
  UZB: 'Uzbekistan',
  VEN: 'Venezuela (Bolivarian Republic of)',
  VNM: 'Viet Nam',
  YEM: 'Yemen',
  ZMB: 'Zambia',
  ZWE: 'Zimbabwe',
};

export const COUNTRIES_CODE_MAP = {
  afghanistan: 'AFG',
  albania: 'ALB',
  algeria: 'DZA',
  andorra: 'AND',
  angola: 'AGO',
  'antigua and barbuda': 'ATG',
  argentina: 'ARG',
  armenia: 'ARM',
  australia: 'AUS',
  austria: 'AUT',
  azerbaijan: 'AZE',
  bahamas: 'BHS',
  bahrain: 'BHR',
  bangladesh: 'BGD',
  barbados: 'BRB',
  belarus: 'BLR',
  belgium: 'BEL',
  belize: 'BLZ',
  benin: 'BEN',
  bhutan: 'BTN',
  'bolivia (plurinational state of)': 'BOL',
  'bosnia and herzegovina': 'BIH',
  botswana: 'BWA',
  brazil: 'BRA',
  'brunei darussalam': 'BRN',
  bruneidarussalam: 'BRN',
  bulgaria: 'BGR',
  'burkina faso': 'BFA',
  burundi: 'BDI',
  cambodia: 'KHM',
  cameroon: 'CMR',
  canada: 'CAN',
  'cabo verde': 'CPV',
  'central african republic': 'CAF',
  chad: 'TCD',
  chile: 'CHL',
  china: 'CHN',
  colombia: 'COL',
  comoros: 'COM',
  congo: 'COG',
  'costa rica': 'CRI',
  'côte d\'ivoire': 'CIV',
  croatia: 'HRV',
  cuba: 'CUB',
  cyprus: 'CYP',
  czechia: 'CZE',
  'democratic people\'s republic of korea': 'PRK',
  'democratic republic of the congo': 'COD',
  denmark: 'DNK',
  djibouti: 'DJI',
  dominica: 'DMA',
  'dominican republic': 'DOM',
  ecuador: 'ECU',
  egypt: 'EGY',
  'el salvador': 'SLV',
  'equatorial guinea': 'GNQ',
  eritrea: 'ERI',
  estonia: 'EST',
  ethiopia: 'ETH',
  fiji: 'FJI',
  finland: 'FIN',
  france: 'FRA',
  gabon: 'GAB',
  gambia: 'GMB',
  georgia: 'GEO',
  germany: 'DEU',
  ghana: 'GHA',
  greece: 'GRC',
  grenada: 'GRD',
  guatemala: 'GTM',
  guinea: 'GIN',
  'guinea-bissau': 'GNB',
  guyana: 'GUY',
  haiti: 'HTI',
  honduras: 'HND',
  hungary: 'HUN',
  iceland: 'ISL',
  india: 'IND',
  indonesia: 'IDN',
  'iran (islamic republic of)': 'IRN',
  iraq: 'IRQ',
  ireland: 'IRL',
  israel: 'ISR',
  italy: 'ITA',
  jamaica: 'JAM',
  japan: 'JPN',
  jordan: 'JOR',
  kazakhstan: 'KAZ',
  kenya: 'KEN',
  kuwait: 'KWT',
  kyrgyzstan: 'KGZ',
  'lao people\'s democratic republic': 'LAO',
  latvia: 'LVA',
  lebanon: 'LBN',
  lesotho: 'LSO',
  liberia: 'LBR',
  libya: 'LBY',
  liechtenstein: 'LIE',
  lithuania: 'LTU',
  luxembourg: 'LUX',
  madagascar: 'MDG',
  malawi: 'MWI',
  malaysia: 'MYS',
  maldives: 'MDV',
  mali: 'MLI',
  malta: 'MLT',
  mauritania: 'MRT',
  mauritius: 'MUS',
  mexico: 'MEX',
  monaco: 'MCO',
  mongolia: 'MNG',
  montenegro: 'MNE',
  morocco: 'MAR',
  mozambique: 'MOZ',
  myanmar: 'MMR',
  namibia: 'NAM',
  nepal: 'NPL',
  netherlands: 'NLD',
  'new zealand': 'NZL',
  nicaragua: 'NIC',
  niger: 'NER',
  nigeria: 'NGA',
  norway: 'NOR',
  'occupied palestinian territory': 'PSE',
  oman: 'OMN',
  pakistan: 'PAK',
  panama: 'PAN',
  'papua new guinea': 'PNG',
  paraguay: 'PRY',
  peru: 'PER',
  philippines: 'PHL',
  poland: 'POL',
  portugal: 'PRT',
  'puerto rico (united states of america)': 'PRI',
  qatar: 'QAT',
  'republic of korea': 'KOR',
  'republic of moldova': 'MDA',
  romania: 'ROU',
  'russian federation': 'RUS',
  rwanda: 'RWA',
  samoa: 'WSM',
  'saint kitts and nevis': 'KNA',
  'saint lucia': 'LCA',
  'saint vincent and the grenadines': 'VCT',
  'san marino': 'SMR',
  'sao tome and principe': 'STP',
  'saudi arabia': 'SAU',
  senegal: 'SEN',
  serbia: 'SRB',
  seychelles: 'SYC',
  'sierra leone': 'SLE',
  singapore: 'SGP',
  slovakia: 'SVK',
  slovenia: 'SVN',
  somalia: 'SOM',
  'solomon islands': 'SLB',
  'south africa': 'ZAF',
  'south sudan': 'SSD',
  spain: 'ESP',
  'sri lanka': 'LKA',
  sudan: 'SDN',
  'sudan (pre-2011)': 'SDN',
  suriname: 'SUR',
  swaziland: 'SWZ',
  sweden: 'SWE',
  switzerland: 'CHE',
  'syrian arab republic': 'SYR',
  tajikistan: 'TJK',
  thailand: 'THA',
  'the former yugoslav republic of macedonia': 'MKD',
  'timor-leste': 'TLS',
  togo: 'TGO',
  'trinidad and tobago': 'TTO',
  tunisia: 'TUN',
  turkey: 'TUR',
  turkmenistan: 'TKM',
  uganda: 'UGA',
  ukraine: 'UKR',
  'united arab emirates': 'ARE',
  'united kingdom': 'GBR',
  'united republic of tanzania': 'TZA',
  'united states of america': 'USA',
  uruguay: 'URY',
  uzbekistan: 'UZB',
  vanuatu: 'VUT',
  'venezuela (bolivarian republic of)': 'VEN',
  'viet nam': 'VNM',
  yemen: 'YEM',
  zambia: 'ZMB',
  zimbabwe: 'ZWE',
};
