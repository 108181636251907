import { COUNTRIES_MAP, COUNTRIES_CODE_MAP } from './countries';

export const menuNodesSorter = (edgeA, edgeB) => {
  const menuOrderA = Number(edgeA.node.frontmatter.menuOrder) || 0;
  const menuOrderB = Number(edgeB.node.frontmatter.menuOrder) || 0;

  if (menuOrderA > menuOrderB) {
    return 1;
  }

  if (menuOrderA < menuOrderB) {
    return -1;
  }

  return 0;
};

export const listOrderSorter = (edgeA, edgeB) => {
  const menuOrderA = Number(edgeA.node.frontmatter.listOrder) || 0;
  const menuOrderB = Number(edgeB.node.frontmatter.listOrder) || 0;

  if (menuOrderA > menuOrderB) {
    return 1;
  }

  if (menuOrderA < menuOrderB) {
    return -1;
  }

  return 0;
};

export const yearOrderSorter = (edgeA, edgeB) => {
  const menuOrderA = Number(edgeA.node.frontmatter.year) || 0;
  const menuOrderB = Number(edgeB.node.frontmatter.year) || 0;

  if (menuOrderA > menuOrderB) {
    return -1;
  }

  if (menuOrderA < menuOrderB) {
    return 1;
  }

  return 0;
};

export const getCountryNameByCode = (countryCode) => COUNTRIES_MAP[countryCode];

export const getCodeByCountryName = (countryName) => COUNTRIES_CODE_MAP[countryName];

export const countriesWithoutDams = [
  'BRB',
  'SOM',
  'AND',
  'BHR',
  'TCD',
  'COM',
  'DJI',
  'DMA',
  'VCT',
  'CPV',
  'GNQ',
  'KWT',
  'MCO',
  'GNB',
  'ISR',
  'SYC',
  'SMR',
  'QAT',
  'TLS',
  'PRI',
  'KNA',
  'STP',
  'MLT',
  'LIE',
  'PSE',
  'MDV',
];
