import React from 'react';

import styles from './styles.module.scss';

/* eslint-disable */
const FaoLogo = () => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 295.5 56.7"
    style={{height: '60px'}}
  >
    <g>
      <g>
        <path className={styles.st0} d="M67.6,18.9h5.6v1.6h-3.6v3H73v1.6h-3.4V30h-2L67.6,18.9L67.6,18.9z"/>
        <path className={styles.st0} d="M77.7,21.7c1.7,0,3.6,0.9,3.6,4.2c0,3.5-1.9,4.3-3.6,4.3S74,29.4,74,25.9C74,22.5,76,21.7,77.7,21.7z
           M77.7,28.7c1.3,0,1.6-1.5,1.6-2.8s-0.3-2.8-1.6-2.8c-1.3,0-1.6,1.5-1.6,2.8S76.4,28.7,77.7,28.7z"/>
        <path className={styles.st0} d="M86.3,21.7c1.7,0,3.6,0.9,3.6,4.2c0,3.5-1.9,4.3-3.6,4.3c-1.7,0-3.6-0.8-3.6-4.3
          C82.6,22.5,84.6,21.7,86.3,21.7z M86.3,28.7c1.3,0,1.6-1.5,1.6-2.8s-0.3-2.8-1.6-2.8c-1.3,0-1.6,1.5-1.6,2.8S85,28.7,86.3,28.7z"
          />
        <path className={styles.st0} d="M98.2,18v10.6c0,0.6,0,1.1,0.1,1.4h-1.9c0-0.2-0.1-0.7-0.1-1.3h0c-0.3,0.7-0.9,1.4-2.2,1.4
          c-2,0-2.9-2-2.9-4.2c0-2.7,1.1-4.3,2.8-4.3c1.2,0,1.8,0.7,2.1,1.3h0V18H98.2z M94.7,28.7c1.2,0,1.6-1.3,1.6-2.8
          c0-1.6-0.4-2.8-1.5-2.8c-1.1,0-1.5,1.1-1.5,2.7C93.2,27.7,93.5,28.7,94.7,28.7z"/>
        <path className={styles.st0} d="M104.6,22.3c0.6-0.3,1.3-0.6,2.6-0.6c2.6,0,3.2,1.2,3.2,3.3v3.6c0,0.6,0.1,1.1,0.1,1.5h-1.8
          c-0.1-0.3-0.1-0.7-0.1-1.1h0c-0.5,0.7-1.1,1.2-2.3,1.2c-1.3,0-2.5-0.9-2.5-2.4c0-2.2,1.8-2.8,4.3-2.8h0.4v-0.3
          c0-0.8-0.4-1.6-1.5-1.6c-1,0-1.9,0.5-2.3,0.8L104.6,22.3z M108.5,26h-0.2c-1.7,0-2.7,0.4-2.7,1.6c0,0.8,0.5,1.3,1.3,1.3
          c1.2,0,1.6-0.9,1.6-2.4L108.5,26L108.5,26z"/>
        <path className={styles.st0} d="M112.2,23.1c0-0.5,0-1-0.1-1.4h1.9c0,0.4,0.1,0.8,0.1,1.3h0c0.3-0.5,0.8-1.4,2.2-1.4c1.9,0,2.4,1.4,2.4,3.1
          V30h-2v-4.9c0-1.3-0.3-1.8-1.1-1.8c-1,0-1.4,0.9-1.4,1.9V30h-2C112.2,30,112.2,23.1,112.2,23.1z"/>
        <path className={styles.st0} d="M127.2,18v10.6c0,0.6,0,1.1,0.1,1.4h-1.9c0-0.2-0.1-0.7-0.1-1.3h0c-0.3,0.7-0.9,1.4-2.2,1.4
          c-2,0-2.9-2-2.9-4.2c0-2.7,1.1-4.3,2.8-4.3c1.2,0,1.8,0.7,2.1,1.3h0V18H127.2z M123.7,28.7c1.2,0,1.6-1.3,1.6-2.8
          c0-1.6-0.4-2.8-1.5-2.8c-1.1,0-1.5,1.1-1.5,2.7C122.2,27.7,122.5,28.7,123.7,28.7z"/>
        <path className={styles.st0} d="M138.2,27.3h-3.8l-0.8,2.7h-2l3.7-11.2h2.2l3.6,11.2H139L138.2,27.3z M137.8,25.8l-1.4-5.2h0l-1.5,5.2H137.8z
          "/>
        <path className={styles.st0} d="M142.3,31.3c0.5,0.3,1.4,0.7,2.4,0.7c1.9,0,2.2-1.3,2.2-2.6v-0.8h0c-0.3,0.6-0.9,1.4-2.2,1.4
          c-1.1,0-2.9-0.8-2.9-4.1c0-2.3,0.8-4.3,3-4.3c1.2,0,1.8,0.7,2.2,1.4h0.1c0-0.4,0-0.8,0.1-1.3h1.8c0,0.4-0.1,0.9-0.1,1.3v6.1
          c0,2.6-0.8,4.4-3.8,4.4c-1.3,0-2.4-0.3-2.8-0.5L142.3,31.3z M145.2,28.6c1.2,0,1.7-0.9,1.7-2.8c0-1.7-0.7-2.7-1.6-2.6
          c-1,0-1.4,1-1.4,2.7C143.9,27.8,144.5,28.6,145.2,28.6z"/>
        <path className={styles.st0} d="M150.7,23.1c0-0.5,0-1-0.1-1.4h1.8c0,0.5,0.1,1,0.1,1.5h0c0.2-0.6,0.9-1.6,2.1-1.6c0.1,0,0.2,0,0.3,0v1.9
          c-0.2,0-0.4-0.1-0.6-0.1c-0.8,0-1.7,0.5-1.7,2V30h-2L150.7,23.1L150.7,23.1z"/>
        <path className={styles.st0} d="M156.5,18.3h2v2h-2V18.3z M156.5,21.8h2V30h-2V21.8z"/>
        <path className={styles.st0} d="M166,29.8c-0.5,0.2-1.2,0.3-2,0.3c-2.7,0-3.9-1.9-3.9-4.2c0-2.4,1.2-4.3,4-4.3c0.6,0,1.4,0.2,1.7,0.3
          l-0.1,1.6c-0.4-0.2-0.8-0.4-1.4-0.4c-1.5,0-2.2,1-2.2,2.8c0,1.5,0.7,2.7,2.3,2.7c0.5,0,1.1-0.2,1.4-0.4L166,29.8z"/>
        <path className={styles.st0} d="M173.8,28.7c0,0.5,0,1,0.1,1.4H172c0-0.4-0.1-0.8-0.1-1.3l0,0l0,0c-0.4,0.6-0.9,1.4-2.2,1.4
          c-1.9,0-2.4-1.4-2.4-3.1v-5.3h2v4.9c0,1.3,0.4,1.8,1.2,1.8c1.1,0,1.4-1,1.4-2v-4.7h2L173.8,28.7L173.8,28.7z"/>
        <path className={styles.st0} d="M175.8,18h2v12h-2V18z"/>
        <path className={styles.st0} d="M180.4,20.1l2-0.6v2.3h1.6v1.5h-1.6v4.3c0,0.9,0.3,1.1,0.9,1.1c0.4,0,0.6-0.1,0.8-0.2v1.4
          c-0.3,0.1-0.7,0.3-1.3,0.3c-1.5,0-2.3-0.7-2.3-2.4v-4.5h-1.3v-1.5h1.3L180.4,20.1L180.4,20.1z"/>
        <path className={styles.st0} d="M191.8,28.7c0,0.5,0,1,0.1,1.4h-1.9c0-0.4-0.1-0.8-0.1-1.3l0,0l0,0c-0.4,0.6-0.9,1.4-2.2,1.4
          c-1.9,0-2.4-1.4-2.4-3.1v-5.3h2v4.9c0,1.3,0.4,1.8,1.2,1.8c1.1,0,1.4-1,1.4-2v-4.7h2L191.8,28.7L191.8,28.7z"/>
        <path className={styles.st0} d="M193.7,23.1c0-0.5,0-1-0.1-1.4h1.8c0,0.5,0.1,1,0.1,1.5h0c0.2-0.6,0.9-1.6,2.1-1.6c0.1,0,0.2,0,0.3,0v1.9
          c-0.2,0-0.4-0.1-0.6-0.1c-0.8,0-1.7,0.5-1.7,2V30h-2V23.1z"/>
        <path className={styles.st0} d="M205.1,29.6c-0.4,0.2-1.3,0.5-2.5,0.5c-2.7,0-3.8-1.9-3.8-4.1c0-2.5,1.3-4.4,3.4-4.4c1.8,0,3.3,1,3.3,4.4v0.4
          h-4.8c0,1.4,0.6,2.3,2.1,2.3c1.2,0,1.8-0.4,2.2-0.6L205.1,29.6z M203.6,25.2c0-1.5-0.5-2.2-1.4-2.2c-1,0-1.5,1.1-1.5,2.2H203.6z"
          />
        <path className={styles.st0} d="M215.5,18.7c2.3,0,4.4,1.7,4.4,5.7c0,4-2.1,5.7-4.4,5.7c-2.3,0-4.4-1.7-4.4-5.7
          C211.1,20.4,213.2,18.7,215.5,18.7z M215.5,28.6c1.3,0,2.3-1.1,2.3-4.2s-1-4.2-2.3-4.2c-1.3,0-2.3,1.1-2.3,4.2
          S214.2,28.6,215.5,28.6z"/>
        <path className={styles.st0} d="M221.7,23.1c0-0.5,0-1-0.1-1.4h1.8c0,0.5,0.1,1,0.1,1.5h0c0.2-0.6,0.9-1.6,2.1-1.6c0.1,0,0.2,0,0.3,0v1.9
          c-0.2,0-0.4-0.1-0.6-0.1c-0.8,0-1.7,0.5-1.7,2V30h-2L221.7,23.1L221.7,23.1z"/>
        <path className={styles.st0} d="M227.3,31.3c0.5,0.3,1.4,0.7,2.4,0.7c1.9,0,2.2-1.3,2.2-2.6v-0.8h0c-0.3,0.6-0.9,1.4-2.2,1.4
          c-1.2,0-2.9-0.8-2.9-4.1c0-2.3,0.8-4.3,3-4.3c1.2,0,1.8,0.7,2.2,1.4h0.1c0-0.4,0-0.8,0.1-1.3h1.8c0,0.4-0.1,0.9-0.1,1.3v6.1
          c0,2.6-0.8,4.4-3.8,4.4c-1.3,0-2.4-0.3-2.8-0.5L227.3,31.3z M230.2,28.6c1.2,0,1.7-0.9,1.7-2.8c0-1.7-0.7-2.7-1.6-2.6
          c-1,0-1.4,1-1.4,2.7C228.9,27.8,229.5,28.6,230.2,28.6z"/>
        <path className={styles.st0} d="M236.1,22.3c0.6-0.3,1.3-0.6,2.6-0.6c2.6,0,3.1,1.2,3.1,3.3v3.6c0,0.6,0.1,1.1,0.1,1.5h-1.8
          c-0.1-0.3-0.1-0.7-0.1-1.1h0c-0.5,0.7-1.1,1.2-2.3,1.2c-1.3,0-2.5-0.9-2.5-2.4c0-2.2,1.8-2.8,4.3-2.8h0.4v-0.3
          c0-0.8-0.4-1.6-1.5-1.6c-1,0-1.9,0.5-2.3,0.8L236.1,22.3z M240,26h-0.2c-1.7,0-2.7,0.4-2.7,1.6c0,0.8,0.5,1.3,1.3,1.3
          c1.2,0,1.6-0.9,1.6-2.4L240,26L240,26z"/>
        <path className={styles.st0} d="M243.6,23.1c0-0.5,0-1-0.1-1.4h1.9c0,0.4,0.1,0.8,0.1,1.3h0c0.3-0.5,0.8-1.4,2.2-1.4c1.9,0,2.4,1.4,2.4,3.1
          V30h-2v-4.9c0-1.3-0.3-1.8-1.1-1.8c-1,0-1.4,0.9-1.4,1.9V30h-2L243.6,23.1L243.6,23.1z"/>
        <path className={styles.st0} d="M252.1,18.3h2v2h-2V18.3z M252.1,21.8h2V30h-2V21.8z"/>
        <path className={styles.st0} d="M255.7,28.3l3.5-5h-3.4v-1.5h5.4v1.7l-3.5,5h3.6V30h-5.6L255.7,28.3L255.7,28.3z"/>
        <path className={styles.st0} d="M263,22.3c0.6-0.3,1.3-0.6,2.6-0.6c2.6,0,3.1,1.2,3.1,3.3v3.6c0,0.6,0.1,1.1,0.1,1.5h-1.8
          c-0.1-0.3-0.1-0.7-0.1-1.1h0c-0.5,0.7-1.1,1.2-2.3,1.2c-1.3,0-2.5-0.9-2.5-2.4c0-2.2,1.8-2.8,4.3-2.8h0.4v-0.3
          c0-0.8-0.4-1.6-1.5-1.6c-1,0-1.9,0.5-2.3,0.8L263,22.3z M266.9,26h-0.2c-1.7,0-2.7,0.4-2.7,1.6c0,0.8,0.5,1.3,1.3,1.3
          c1.2,0,1.6-0.9,1.6-2.4V26z"/>
        <path className={styles.st0} d="M271.3,20.1l2-0.6v2.3h1.6v1.5h-1.6v4.3c0,0.9,0.3,1.1,0.9,1.1c0.4,0,0.6-0.1,0.8-0.2v1.4
          c-0.3,0.1-0.7,0.3-1.3,0.3c-1.5,0-2.3-0.7-2.3-2.4v-4.5H270v-1.5h1.3L271.3,20.1L271.3,20.1z"/>
        <path className={styles.st0} d="M276.4,18.3h2v2h-2V18.3z M276.4,21.8h2V30h-2V21.8z"/>
        <path className={styles.st0} d="M283.8,21.7c1.7,0,3.6,0.9,3.6,4.2c0,3.5-1.9,4.3-3.6,4.3c-1.7,0-3.6-0.8-3.6-4.3
          C280.2,22.5,282.1,21.7,283.8,21.7z M283.8,28.7c1.3,0,1.6-1.5,1.6-2.8s-0.3-2.8-1.6-2.8s-1.6,1.5-1.6,2.8S282.5,28.7,283.8,28.7z
          "/>
        <path className={styles.st0} d="M289,23.1c0-0.5,0-1-0.1-1.4h1.9c0,0.4,0.1,0.8,0.1,1.3h0c0.3-0.5,0.8-1.4,2.2-1.4c1.9,0,2.4,1.4,2.4,3.1V30
          h-2v-4.9c0-1.3-0.3-1.8-1.1-1.8c-1,0-1.4,0.9-1.4,1.9V30h-2L289,23.1L289,23.1z"/>
        <path className={styles.st0} d="M70.7,39.7c1.7,0,3.6,0.9,3.6,4.2c0,3.5-1.9,4.3-3.6,4.3S67,47.4,67,44C67,40.6,69,39.7,70.7,39.7z
           M70.7,46.8c1.3,0,1.6-1.5,1.6-2.8s-0.3-2.8-1.6-2.8c-1.3,0-1.6,1.5-1.6,2.8S69.4,46.8,70.7,46.8z"/>
        <path className={styles.st0} d="M76.5,41.3h-1.4v-1.5h1.4v-1.7c0-1.2,0.6-2.3,2.4-2.3c0.5,0,1,0.1,1.4,0.2l-0.1,1.4c-0.2-0.1-0.5-0.1-0.8-0.1
          c-0.6,0-0.9,0.4-0.9,1v1.4H80v1.5h-1.5v6.8h-2C76.5,48.1,76.5,41.3,76.5,41.3z"/>
        <path className={styles.st0} d="M86.1,38.2l2-0.6v2.3h1.6v1.5H88v4.3c0,0.9,0.3,1.1,0.9,1.1c0.4,0,0.6-0.1,0.8-0.2V48
          c-0.3,0.1-0.7,0.3-1.3,0.3c-1.5,0-2.3-0.7-2.3-2.4v-4.5h-1.3v-1.5h1.3C86.1,39.9,86.1,38.2,86.1,38.2z"/>
        <path className={styles.st0} d="M91,36.1h2v4.8h0c0.4-0.5,0.8-1.2,2-1.2c1.9,0,2.4,1.4,2.4,3.1v5.3h-2v-4.9c0-1.3-0.3-1.8-1.1-1.8
          c-1,0-1.4,0.9-1.4,1.9v4.8h-2L91,36.1L91,36.1z"/>
        <path className={styles.st0} d="M105.3,47.7c-0.4,0.2-1.3,0.5-2.5,0.5c-2.7,0-3.8-1.9-3.8-4.1c0-2.5,1.3-4.4,3.4-4.4c1.8,0,3.3,1,3.3,4.4v0.4
          h-4.8c0,1.4,0.6,2.3,2.1,2.3c1.2,0,1.8-0.4,2.2-0.6L105.3,47.7z M103.8,43.3c0-1.5-0.5-2.2-1.4-2.2c-1,0-1.5,1.1-1.5,2.2H103.8z"
          />
        <path className={styles.st0} d="M113.6,36.9v7.2c0,1.9,0.6,2.5,1.8,2.5c1.2,0,1.7-0.6,1.7-2.5v-7.2h2v7.2c0,2.9-1.7,4.1-3.8,4.1
          c-2.1,0-3.8-1.2-3.8-4.1v-7.2C111.6,36.9,113.6,36.9,113.6,36.9z"/>
        <path className={styles.st0} d="M121.2,41.2c0-0.5,0-1-0.1-1.4h1.9c0,0.4,0.1,0.8,0.1,1.3h0c0.3-0.5,0.8-1.4,2.2-1.4c1.9,0,2.4,1.4,2.4,3.1
          v5.3h-2v-4.9c0-1.3-0.3-1.8-1.1-1.8c-1,0-1.4,0.9-1.4,1.9v4.8h-2L121.2,41.2L121.2,41.2z"/>
        <path className={styles.st0} d="M129.6,36.4h2v2h-2V36.4z M129.7,39.9h2v8.2h-2V39.9z"/>
        <path className={styles.st0} d="M134.4,38.2l2-0.6v2.3h1.6v1.5h-1.6v4.3c0,0.9,0.3,1.1,0.9,1.1c0.4,0,0.6-0.1,0.8-0.2V48
          c-0.3,0.1-0.7,0.3-1.3,0.3c-1.5,0-2.3-0.7-2.3-2.4v-4.5H133v-1.5h1.3V38.2z"/>
        <path className={styles.st0} d="M145.3,47.7c-0.4,0.2-1.3,0.5-2.5,0.5c-2.7,0-3.8-1.9-3.8-4.1c0-2.5,1.3-4.4,3.4-4.4c1.8,0,3.3,1,3.3,4.4v0.4
          h-4.8c0,1.4,0.6,2.3,2.1,2.3c1.2,0,1.8-0.4,2.2-0.6L145.3,47.7z M143.8,43.3c0-1.5-0.5-2.2-1.4-2.2c-1,0-1.5,1.1-1.5,2.2H143.8z"
          />
        <path className={styles.st0} d="M154,36.1v10.6c0,0.6,0,1.1,0.1,1.4h-1.9c0-0.2-0.1-0.7-0.1-1.3h0c-0.3,0.7-0.9,1.4-2.2,1.4
          c-2,0-2.9-2-2.9-4.2c0-2.7,1.1-4.3,2.8-4.3c1.2,0,1.8,0.7,2.1,1.3h0v-4.9L154,36.1L154,36.1z M150.4,46.8c1.2,0,1.6-1.3,1.6-2.8
          c0-1.6-0.4-2.8-1.5-2.8c-1.1,0-1.5,1.1-1.5,2.7C149,45.8,149.3,46.8,150.4,46.8z"/>
        <path className={styles.st0} d="M160.2,36.9h2.4l3.7,8.8h0v-8.8h1.9v11.2h-2.4l-3.7-8.7h0v8.7h-1.9L160.2,36.9L160.2,36.9z"/>
        <path className={styles.st0} d="M170.7,40.3c0.6-0.3,1.3-0.6,2.6-0.6c2.6,0,3.1,1.2,3.1,3.3v3.6c0,0.6,0.1,1.1,0.1,1.5h-1.8
          c-0.1-0.3-0.1-0.7-0.1-1.1h0c-0.5,0.7-1.1,1.2-2.3,1.2c-1.3,0-2.5-0.9-2.5-2.4c0-2.2,1.8-2.8,4.3-2.8h0.4v-0.3
          c0-0.8-0.4-1.6-1.5-1.6c-1,0-1.9,0.5-2.3,0.8L170.7,40.3z M174.6,44.1h-0.2c-1.7,0-2.7,0.4-2.7,1.6c0,0.8,0.5,1.3,1.3,1.3
          c1.2,0,1.6-0.9,1.6-2.3L174.6,44.1L174.6,44.1z"/>
        <path className={styles.st0} d="M179.1,38.2l2-0.6v2.3h1.6v1.5H181v4.3c0,0.9,0.3,1.1,0.9,1.1c0.4,0,0.6-0.1,0.8-0.2V48
          c-0.3,0.1-0.7,0.3-1.3,0.3c-1.5,0-2.3-0.7-2.3-2.4v-4.5h-1.3v-1.5h1.3L179.1,38.2L179.1,38.2z"/>
        <path className={styles.st0} d="M184.1,36.4h2v2h-2V36.4z M184.1,39.9h2v8.2h-2V39.9z"/>
        <path className={styles.st0} d="M191.5,39.7c1.7,0,3.6,0.9,3.6,4.2c0,3.5-1.9,4.3-3.6,4.3s-3.6-0.8-3.6-4.3C187.9,40.6,189.8,39.7,191.5,39.7
          z M191.5,46.8c1.3,0,1.6-1.5,1.6-2.8s-0.3-2.8-1.6-2.8c-1.3,0-1.6,1.5-1.6,2.8S190.2,46.8,191.5,46.8z"/>
        <path className={styles.st0} d="M196.7,41.2c0-0.5,0-1-0.1-1.4h1.9c0,0.4,0.1,0.8,0.1,1.3h0c0.3-0.5,0.8-1.4,2.2-1.4c1.9,0,2.4,1.4,2.4,3.1
          v5.3h-2v-4.9c0-1.3-0.3-1.8-1.1-1.8c-1,0-1.4,0.9-1.4,1.9v4.8h-2V41.2z"/>
        <path className={styles.st0} d="M204.8,46.2c0.3,0.1,1.1,0.5,2.1,0.5c0.7,0,1.3-0.2,1.3-0.8c0-0.3-0.2-0.6-0.9-1l-0.8-0.5
          c-0.8-0.5-1.7-1-1.7-2.3c0-1.1,1.1-2.3,2.8-2.3c1.1,0,1.9,0.3,2.1,0.3l-0.1,1.5c-0.5-0.2-1-0.4-1.7-0.4c-0.8,0-1.1,0.3-1.1,0.8
          c0,0.4,0.4,0.7,0.9,1.1l1,0.6c1,0.7,1.5,1.1,1.5,2.1c0,1.5-1.3,2.4-3.1,2.4c-1,0-1.9-0.2-2.3-0.4L204.8,46.2z"/>
      </g>
    </g>
    <g>
      <path className={styles.st0} d="M28.3,56.7C12.7,56.7,0,44,0,28.3S12.7,0,28.3,0C44,0,56.7,12.7,56.7,28.3S44,56.7,28.3,56.7z M28.2,17.2
        c0,0,2.3,2.4,2.3,4.4c0,2.1-1.6,2.6-2.2,2.6c0,0-0.1,0-0.1,0h0c0,0-0.1,0-0.1,0c-0.6,0-2.2-0.5-2.2-2.6
        C25.9,19.6,28.2,17.2,28.2,17.2z M6.9,29.9h2.6v-4.8h4.3v-2H9.5v-2.7H14v-2H6.9V29.9z M30.6,17.5h2.7L30.1,6.1h-3.5l-3.3,11.4h2.5
        l0.7-2.6h3.3L30.6,17.5z M29.4,13h-2.4l1.1-5h0.2L29.4,13z M41.4,24.2c0,3.4,1.6,5.9,4.8,5.9c3.3,0,4.9-2.6,4.9-5.9
        c0-3.4-1.6-5.9-4.9-5.9C43,18.3,41.4,20.9,41.4,24.2z M44.2,24.2c0-2.2,0.5-4,2.1-4c1.6,0,2.1,1.8,2.1,4s-0.6,4-2.1,4
        C44.7,28.2,44.2,26.4,44.2,24.2z M27.1,53.5c0.2-1.1,0.3-2.9,0.3-5c0-0.6,0-0.2,0-0.3c0-2.9-0.1-3.9-2.1-4.6c-3-1-4.3-4.4-3.6-8.6
        L11.7,9.4c-5.2,4.6-8.6,11.4-8.6,18.9C3.1,41.8,13.8,52.9,27.1,53.5z M28.3,37.2c-4-0.9-5.5-4.6-5.5-7.8L14,7.6
        c-0.6,0.4-1.1,0.8-1.7,1.3C14.3,13.5,23,34.2,23,34.2c1.5,3.7,4.4,2.5,5,5.6c0.2,0.7,0.2,1.1,0.2,1.1h0.3c0,0,0-0.3,0.2-1.1
        c0.7-3.1,3.5-1.8,5-5.6c0,0,8.7-20.7,10.7-25.3c-0.5-0.4-1.1-0.9-1.7-1.3l-8.8,21.7C33.9,32.6,32.4,36.2,28.3,37.2L28.3,37.2z
         M28.3,30.6c-1.7-0.4-3.8-2.1-4.1-4.2c-0.1-0.8-0.2-1.1-0.1-2.7L16.4,6.1c-0.6,0.3-1.2,0.7-1.8,1.1c2.3,5.2,9.4,21.1,9.4,21.1
        c1.1,2.4,3.1,2.4,3.8,3.8c0.4,0.8,0.4,1.6,0.5,1.6h0.3c0-0.1,0-0.8,0.5-1.6c0.7-1.4,2.6-1.5,3.8-3.8c0,0,7.1-15.9,9.4-21.1
        c-0.6-0.4-1.2-0.8-1.8-1.1l-7.7,17.5c0.1,1.6,0,1.9-0.1,2.7C32.2,28.5,30.1,30.2,28.3,30.6L28.3,30.6z M28.4,27.6l0.1-1
        c0.5-2.2,2.1-1.9,3.2-4.1c0,0,0,0,0,0c0-0.1,5.5-11.8,7.8-16.7c-3.4-1.7-7.2-2.7-11.2-2.7c-4,0-7.9,1-11.2,2.7
        c2.3,4.9,7.8,16.7,7.8,16.7c0,0,0,0,0,0c1.1,2.2,2.7,1.9,3.2,4.1l0.1,1C28.2,27.6,28.4,27.6,28.4,27.6z M53.6,28.3
        c0-7.5-3.3-14.3-8.6-18.9L34.9,35.1c0.7,4.2-0.6,7.6-3.6,8.6c-2,0.6-2.1,1.6-2.1,4.6c0,0.2,0-0.2,0,0.3c0,2.1,0.2,3.9,0.3,5
        C42.9,52.9,53.6,41.8,53.6,28.3z M11.1,35.8c0,0,0.4,0.8,0.7,1.4c0.3,0.6,0.7,1.2,0.7,1.2l-0.7,0.4c0,0-0.5-0.8-1-1.8
        c-0.1,0.1-0.9,0.5-1.1,0.5c0.1,0.1,0.9,1.7,0.9,1.7L10,39.6c0,0-0.8-1.6-0.9-1.7c-0.1,0.1-1.9,1-1.9,1L6.8,38L11.1,35.8L11.1,35.8z
         M13.8,40.1l0.6,0.7l-3.7,3.1l-0.6-0.7L13.8,40.1L13.8,40.1z M17.2,43.3l0.7,0.5L17,48.9l-0.8-0.5c0,0,0.2-1,0.2-1.1
        c0,0-0.5-0.3-1-0.7c-0.5-0.3-0.9-0.7-1-0.8c-0.1,0.1-1,0.6-1,0.6l-0.7-0.6L17.2,43.3L17.2,43.3z M15,45.5c0.1,0.1,0.4,0.3,0.7,0.6
        c0.3,0.2,0.7,0.4,0.8,0.5c0-0.2,0.4-1.9,0.5-2.2C16.7,44.5,15.2,45.4,15,45.5z M20.2,45.2c0,0,0.8,0.4,1.9,0.8
        c0.8,0.3,1.4,0.5,2,0.6l-0.2,0.7c0,0-0.6-0.1-1.6-0.4c-0.1,0.2-1.3,3.8-1.3,3.8l-0.9-0.3c0,0,1.3-3.6,1.3-3.8
        c-0.9-0.3-1.5-0.7-1.5-0.7L20.2,45.2z M35.4,46.2c0.3,0.2,0.5,0.5,0.7,0.9c0.1,0.2,0.1,0.3,0.1,0.5c0,0.7-0.5,1.2-1.3,1.5
        c0,0-0.4,0.1-0.6,0.2c0,0.2,0.6,1.8,0.6,1.8L34,51.2l-1.4-4.6l1.3-0.4C34.5,46,35,46,35.4,46.2z M33.6,47c0,0.1,0.4,1.3,0.4,1.4
        c0.2-0.1,0.6-0.2,0.6-0.2c0.1,0,0.4-0.2,0.6-0.4c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2,0-0.2c-0.1-0.2-0.2-0.3-0.3-0.4
        c-0.3-0.1-0.6-0.1-0.8,0C34.1,46.9,33.7,47,33.6,47z M38.2,44.2l4.2,3l-0.7,0.5c0,0-0.8-0.6-0.9-0.7c0,0-0.5,0.3-0.9,0.6
        c-0.4,0.3-0.9,0.5-1,0.5c0,0.1,0.2,1.1,0.2,1.1l-0.8,0.4l-0.7-5.1L38.2,44.2z M38.7,47.4c0.1-0.1,0.4-0.2,0.8-0.4
        c0.3-0.2,0.5-0.3,0.6-0.4c-0.2-0.1-1.5-1.1-1.7-1.3C38.4,45.6,38.7,47.2,38.7,47.4z M43,40l3.6,3.2L46,44c0,0-3.4-1-3.9-1.1
        c0.4,0.4,2.5,2.6,2.5,2.6L43.9,46l-3.4-3.5l0.8-0.7c0,0,3.3,1,3.8,1.1c-0.4-0.4-2.7-2.3-2.7-2.3L43,40z M44.6,37.5l4.2,2.4
        l-0.4,0.7l-4.2-2.4L44.6,37.5z M46.5,33.5l0.7,0.4L47.1,34c-0.2,0.3-0.3,0.4-0.5,0.8c-0.1,0.1-0.1,0.4,0,0.6
        c0.1,0.1,0.2,0.2,0.3,0.2c0.3,0.1,0.6-0.2,0.9-0.5c0.4-0.5,1-1,1.9-0.7c0.9,0.3,1.2,1.1,0.8,2.2c-0.1,0.3-0.3,0.6-0.5,0.9l0,0.1
        l-0.7-0.3l0.1-0.1c0.1-0.1,0.4-0.5,0.5-0.8c0.1-0.3,0.1-0.9-0.4-1c-0.4-0.1-0.7,0.2-1.1,0.5c-0.4,0.4-0.9,1-1.7,0.7
        c-0.4-0.1-0.6-0.3-0.8-0.6c-0.2-0.4-0.2-0.8,0-1.3C46,34.2,46.2,33.8,46.5,33.5L46.5,33.5z"/>
    </g>
  </svg>
);

export default FaoLogo;
