import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Link from 'gatsby-link';

import styles from './styles.module.scss';

const TopMenu = ({ location }) => {
  const links = [
    { label: 'Menu' },
    { label: 'Home', link: '/' },
    { label: 'Overview', link: '/overview/' },
    { label: 'Databases', link: '/databases/' },
    { label: 'Geospatial Information', link: '/geospatial-information/' },
    { label: 'Profiles', link: '/countries-and-basins/' },
    { label: 'Data Analysis', link: '/data-analysis/' },
    { label: 'Activities', link: '/activities/' },
    { label: 'Publications', link: '/publications/' },
  ];

  return (
    <div className={styles.wrapper}>
      {links.map(({ label, link }) => {

        if (label === 'Home') {
          return (
            <Link
              key={label}
              to={link}
              className={styles.homeLink}
              activeClassName={styles.activeLink}
            />
          );
        }

        if (label === 'Menu') {
          return (
            <div key="wrapper">
              <input id="ckbxMenu" key="ckbxMenu" className={styles.checkbox} type="checkbox"  />
              <label key="lblMenu" htmlFor="ckbxMenu" className={styles.mobileMenu} />
            </div>
          );
        }

        if (link.startsWith('http')) {
          return (
            <a key={label} href={link} className={styles.link} target="_blank" rel="noopener noreferrer">
              {label}
            </a>
          );
        }

        const isActive = location ? location.includes(link) : false;
        return (
          <Link key={label} to={link} className={classnames(styles.link, styles.mobileLink, { [styles.activeLink]: isActive })}>
            {label}
          </Link>
        );
      })}
    </div>
  );
};

TopMenu.propTypes = {
  location: PropTypes.string.isRequired,
};

export default TopMenu;
